import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Device } from '@capacitor/device';
import commonService from '@/services/commonFunction.js'
export default{
    async checkIfDirExist(){
        await Filesystem.readdir({
            path: 'CGRID',
            directory: Directory.Data
        }).then(async res => {
            this.readHistoryFile();
            this.readprofileFile();
        }).catch (async err=>{
            if(err.message.includes('does not exist') || err.message.includes('there is no such file'))
                await this.createDocumentDirectory();
        })
    },
    async createDocumentDirectory(){
        Filesystem.mkdir({
            path: 'CGRID',
            directory: Directory.Data,
            recursive: false,
        }).then(async res=>{
            this.readHistoryFile();
            this.readprofileFile();
        })
    },
    async createHistoryFile(data){
        return await Filesystem.writeFile({
            path: 'CGRID/CG-HISTORY.txt',
            directory: Directory.Data,
            data:data,
            encoding: Encoding.UTF8,
        })
    },
    async readHistoryFile(){
        let fileData = await Filesystem.readFile({
            path: 'CGRID/CG-HISTORY.txt',
            directory: Directory.Data,
            encoding: Encoding.UTF8,
        }).catch((err)=>{
            if(err.message.includes('does not exist') || err.message.includes('there is no such file')){
                this.createHistoryFile(JSON.stringify([]))
            }
        })
        if(fileData)
            return JSON.parse(fileData.data)
        else
            return []
            //return [{id:5,cols:3,rows:3,grid_unit:9,elapsed_time:'24.83',slowest_number:9,slowest:'20.311',date:'12-12-2002'}]
    },
    async appendHistoryFile(gridData){
        let fileData = await this.readHistoryFile()
        fileData.push(gridData);
        this.createHistoryFile(JSON.stringify(fileData))
        
    },
    async updateHistoryFile(gridData){
        let fileData = await this.readHistoryFile()
        let index = fileData.findIndex (x=> x.gridId == gridData.gridId)
        if(index != -1){
            fileData[index] = {...gridData}
            this.createHistoryFile(JSON.stringify(fileData))
        }
    },
    async createProfileFile(data){
        await Filesystem.writeFile({
            path: 'CGRID/CG-PROFILE.txt',
            directory: Directory.Data,
            data: data,
            encoding: Encoding.UTF8,
        })
    },
    async readprofileFile(){
        let fileData = await Filesystem.readFile({
            path: 'CGRID/CG-PROFILE.txt',
            directory: Directory.Data,
            encoding: Encoding.UTF8,
          }).catch((err)=>{
            if(err.message.includes('does not exist') || err.message.includes('there is no such file'))
                this.createProfileFile(JSON.stringify({username:'Guest',email:''}))
        })
        if(fileData)
            return JSON.parse(fileData.data)
        else 
            return {username:'Guest',email:''}
    },
    async removeOneRecord(gridData){
        let fileData = await this.readHistoryFile()
        let index = fileData.findIndex (x=> x.gridId == gridData.gridId)
        if(index != -1){
            fileData.splice(fileData[index],1);
            this.createHistoryFile(JSON.stringify(fileData))
        }
    },
    async saveScreenshot(data){
        var a = document.createElement("a"); 
        a.href = data.snapshow; 
        a.download = data.snap_name+".png";
        a.click(); 
    },
    async savePhotoFromHistory(gridData){
        if(gridData && gridData.snapshow){
            await this.saveScreenshot(gridData)
        }
    },
    async getGridData(id){
        let fileData = await this.readHistoryFile()
        let index = fileData.findIndex(x=>x.gridId == parseInt(id))
        if(index != -1)
            return fileData[index]
        else{
            commonService.alert({
                title:'Error',
                msg:'Data not found. Please play again.',
                cancelText:"continue",
                showConfirmation:false,
            })
        }
    }
}