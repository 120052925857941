<template>
  <ion-app>
  <router-view v-slot="{Component, route}">
    <div class="parent-div" :class="[route.meta.fullwidth ? '' : 'body-parent', route.meta.removeClass ? 'background-white' : 'background-red' ]" :style="{}">
    <transition :name="route.meta.transition || 'fade'">
      <div :style="{'padding-top': route.meta.margin ? 0 : mt+'px','padding-bottom':mb+'px'}"> <!--'height': BarHeight-->
        <component :is="Component" :key="route.path" />
      </div>
    </transition>
  </div>
  </router-view>
</ion-app>
</template>

<script>
import $ from 'jquery'
import { App as CapacitorApp } from '@capacitor/app';
import commonService from '@/services/commonFunction.js'
import { SafeArea } from 'capacitor-plugin-safe-area';
import { Device } from '@capacitor/device';
import { IonApp } from '@ionic/vue';
import { useBackButton, useIonRouter } from '@ionic/vue';
export default{
  components: {
    IonApp
  },
  data(){
    return {
      mt:0,
      mb:0,
      statusBarHeight : 0
    }
  },
  computed:{
    BarHeight(){
      return $(window).height() - this.statusBarHeight+'px';
    }
  },
  mounted(){
    this.setStatusBarHeight();
    this.backbuttonSubscribeMethod();
    window.addEventListener("orientationchange",()=>{
      this.setStatusBarHeight();
      this.backbuttonSubscribeMethod();
    });
  },
  methods:{
    async setStatusBarHeight(){
      SafeArea.getSafeAreaInsets().then(async ({ insets }) => {
        const info = await Device.getInfo();
        this.mt =  insets.top ;
        this.mb = info.platform === "android" ? 0 : insets.bottom ;
        this.statusBarHeight = this.mt+this.mb
      });
    },
    backbuttonSubscribeMethod(){
      let a = 0;
      useBackButton(-1, () => {
        //event.preventDefault();
          a++;
          if(a<2){
            commonService.showSnackBar('Press again to exit the app')
          }
          if (a == 2) { 
            navigator['app'].exitApp();
          }
          setTimeout(()=>{
            a=0;
          },3000)
      },false);
    }
  }
}
</script>

<style>
.parent-div{
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: auto;
}
.background-red{
    background-color: red;
}
.background-white{
  background-color: #fff !important;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.body-parent{
    width: 100%;
    right: 0;
    left: 0;
    margin: auto;
  }

@media (min-width: 1100px)
{
  .body-parent{
    width: 50%;
    right: 0;
    left: 0;
    margin: auto;
    background: red;
  }
  .body-parent .header_con{
    width: 100%;
  }
  .body-parent #footer {
    width: 50%;
    margin: auto;
  }
  .body-parent .back_icon {
    width: 70%;
    margin-top: -8%;
  }

}
</style>