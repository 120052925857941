import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/splash'
  },
  {
    path: '/splash',
    name: 'splash',
    component: () => import("@/views/Splash.vue"),
    meta:{ margin: true, fullwidth : true} 
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import("@/views/Home.vue"),
    meta:{ transition: 'slide-fade', fullwidth : true} 
  },
  {
    path: '/columns',
    name: 'Coulumns',
    component: () => import("@/views/Columns.vue"),
    meta:{ transition: 'slide-fade'} 
  },
  {
    path: '/gridtemplate',
    name: 'GridTemplate',
    component: () => import("@/views/GridTemplate.vue"),
    meta:{removeClass:true}
  },
  {
    path: '/history-details',
    name: 'HistoryDetails',
    component: () => import("@/views/History/historyDetails.vue"),
    meta:{removeClass:true}
  },
  {
    path: '/share',
    name: 'Share',
    component: () => import("@/views/Share/share.vue"),
  },
  {
    path: '/community',
    name: 'Community',
    component: () => import("@/views/Community/community.vue"),
  },
  {
    path: '/benchmark',
    name: 'BenchMark',
    component: () => import("@/views/Community/benchmark.vue"),
  },
  {
    path: '/administration',
    name: 'Administration',
    component: () => import("@/views/Administration/administration.vue"),
  },
  {
    path: '/sponsor',
    name: 'Sponsor',
    component: () => import("@/views/Administration/sponsor.vue"),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import("@/views/Administration/profile.vue"),
  },
  {
    path: '/history',
    name: 'History',
    component: () => import("@/views/History/history.vue"),
  },
  {
    path: '/datahistory',
    name: 'Datahistory',
    component: () => import("@/views/History/datahistory.vue"),
    meta:{removeClass:true}
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import("@/views/terms.vue"),
    meta:{removeClass:true}
  },
  {
    path: '/about',
    name: 'About',
    component: () => import("@/views/about.vue"),
    meta:{removeClass:true}
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import("@/views/help.vue"),
    meta:{removeClass:true}
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import("@/views/Community/feedback.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: '/splash'
},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
