import axios from 'axios';
const baseURL = "https://concentrationgrid.net/admin/"
const tryGrid = "https://tryconcentrationgrid.com/"

export default{
    getSponsorImage(type = 2){
        return axios.get(`${baseURL}api.php?request=sponser&type=${type}`)
    },
    getSponsorImages(){
        return axios.get(`${baseURL}code/public/api/getActiveBenchmark`)
    },
    getIntialImages(){
        return axios.get(`${baseURL}code/public/api/getActiveSponsorImages`)
    },
    postToLeaderBoard (data) {
        return axios.post(`${baseURL}code/public/api/export`,data)
    },
    getBenchMark(){
        return axios.get(`${baseURL}code/public/api/get/benchmark_thumb/option`)
    },
    postForFacebook(data){
        return axios.post(`${baseURL}code/public/api/upload`,data)
    }
}