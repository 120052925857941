import { toastController,loadingController } from '@ionic/vue';
import $fileService from './fileService.js'
import $sponsorService from './sponsor.js'
import { alertController } from "@ionic/vue"
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';

export default{
    getButtons (props){
        let buttonsArray = []
        if(!props.showCancel && props.showCancel != false  ){
            buttonsArray.push({
                text: props.cancelText ? props.cancelText : 'Cancel',
                cssClass : 'btn-cancel',
                handler: () => {
                    this.closeAlert(props.ErrorCallBackFunc ? props.ErrorCallBackFunc : ()=>{} )
                }
            })
        }
        if(!props.showConfirmation && props.showConfirmation != false ){
            buttonsArray.push({
                text: props.confirmationText ? props.confirmationText : 'Continue',
                cssClass : 'btn-yes',
                handler: () => {
                    this.closeAlert(props.successCallBackFunc ? props.successCallBackFunc : ()=>{} ) 
                }
            })
        }
        return buttonsArray;
    },
    async alert(props = {}){
        const info = await Device.getInfo();
        let cssClass =  'androidClass'

        const modal = await alertController.create({
            header: props.title,
            message: props.msg,
            cssClass :['custom-modal','select-modal',cssClass ],
            backdropDismiss:false,
            buttons: this.getButtons(props)
          })
          
          return modal.present();
    },
    closeAlert (callback) {
        alertController.dismiss();
        if(callback)
            callback();
    },
    async showSnackBar(msg){
        const toast = await toastController.create({
            message: msg,
            duration: 3000,
            position: 'bottom'
          });
  
          await toast.present();
    },
    async loader(msg='',duration = 3000){
        return await loadingController.create({
            message: msg,
            duration : duration
          });
    },
    replyGame(){
        if(localStorage.getItem("last_row_column")){
            let lasteGame = JSON.parse(localStorage.getItem("last_row_column"));
            return { rows : lasteGame.rows, cols : lasteGame.cols }
        }
        return false
    },
    openBrowser(url){
        Browser.open({ url: url })
    },
    async saveHistory(){
        let fileData = await $fileService.readHistoryFile();
        //let profileData = await $fileService.readprofileFile()
        if(fileData.length){
            let date = new Date();		
            let year = date.getFullYear();
            let month = date.getMonth()+1 < 10 ? '0'+ date.getMonth()+1 : date.getMonth()+1;
            let day = date.getDate() < 10 ? '0'+ date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0'+ date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0'+ date.getMinutes() : date.getMinutes();
            let sec = date.getUTCSeconds() < 10 ? '0'+ date.getUTCSeconds() : date.getUTCSeconds();
            let cgDate = year+""+month+""+day+"_"+hour+""+min+""+sec;
            let dynamic_filename = "CG-HISTORY_"+cgDate+".txt";
            let datatoWrite = ''
            fileData.forEach(item=>{ 
                datatoWrite  += item.gen_id+"\r\n"
            })
            const dataURI = "data:text/plain;base64," + encodeBase64(datatoWrite)
            saveAs(dataURI, dynamic_filename);
        }
        else{
            this.alert ({
                title:'Error',
                msg:'No Data',
                cancelText:"Continue",
                showConfirmation:false,
            })
        }
    },
    async saveImageToGallery(gridData){
        $fileService.saveScreenshot(gridData)
    },
    async postToLeaderBoard(gridId,params){
        let loading = await this.loader('Posting to LeaderBoard')
        let gridData = await $fileService.getGridData(gridId);
        let ProfileData = await $fileService.readprofileFile();

        if(gridData.postedgrid ==1){
            let params = {
                title:'Alert',
                msg:'Grid Posted',
                showConfirmation:false,
                cancelText:"Continue"
            }
            this.alert (params)
        }
        else
        {
            let use = ProfileData?.username
            let em = ProfileData?.email ? ProfileData?.email : 'Not Defined'
            
            let data = '';
            let join = '';

            function addParam(name, value)
            {
                if(value == undefined) return;
                data += join + name + '=' + encodeURIComponent(value);
                join = '&';
            }

            addParam("email",em);
            addParam("username",use);
            addParam("thumb",gridData.snapshow);
            addParam("file_name",gridData.snap_name);
            addParam("rows",gridData.rows);
            addParam("cols",gridData.cols);
            addParam("date",gridData.gamedate);
            addParam("elapsed_time",gridData.elapsed_time);
            addParam("grid_id1",gridData.gen_id);
            addParam("grid_id2","");
            addParam("status",1);
            loading.present()
            $sponsorService.postToLeaderBoard(data).then(async data=>
            {
                gridData.postedgrid = 1;
                $fileService.updateHistoryFile(gridData);
                loading.dismiss();
                this.alert (params) 
            })
        }
    },
    async postToFacebook(gridData){
        let loading = await this.loader('Please wait')
        loading.present()

        let data = '';
        let join = '';

        function addParam(name, value)
        {
            if(value == undefined) return;
            data += join + name + '=' + encodeURIComponent(value);
            join = '&';
        }

        addParam("image",gridData.snapshow);
        addParam("filename",gridData.snap_name);

        $sponsorService.postForFacebook(data).then(res=>{
            loading.dismiss();
            FB.init({
                appId: 2318270781800141,
                cookie: true,
                xfbml: true,
                version: 'v8.0' 
            });

            FB.ui({
                method: 'feed',
                link: res.data
            });

        },err=>{
            loading.dismiss();
        })
    },
    async openEmailPopUp(type, data){
        const info = await Device.getInfo();
        let cssClass =  'androidClass'

        let ProfileData = await $fileService.readprofileFile();

        const alert = await alertController.create({
            message: "Enter your email",
            cssClass :['custom-modal email-box','select-modal',cssClass ],
            backdropDismiss:false,
            inputs: [
            {
                name: 'email',
                type: 'email',
                id: 'emailValue',
                value : ProfileData.email,
                placeholder: "Enter Email"
            }],    
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass : 'btn-cancel',
                    handler: () => {
                        console.log('Confirm Cancel');
                    }
                }, 
                {
                    text: 'Continue',
                    cssClass : 'btn-yes',
                    handler: (input) => {
                        if(this.validateEmail(input.email))
                            this.sendEmail(type, data, input.email)
                        else{
                            let params = {
                                title:'Alert',
                                msg:'Email is not corrected',
                                showConfirmation:false,
                                cancelText:"Continue"
                            }
                            this.alert (params)
                        }
                    }
                }
            ]
        });
        await alert.present();
    },
    validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
      },
    sendEmail(type, data,email){
        let ids = data.gen_id_send.split("\r\n")
        let body = ''
        let attachment = []
        if(type == 1){
            body = `<p>Concentration Grid</p>
                    <p>#ConcentrationGrid</p>
                    <p>"${ids[0]}"</p>
                    <p>@ConcentrationGr @Grid_IDx</p>
                    <div style = 'margin-top:30px'>
                        <p>Requested information from Concentration Grid</p>
                        <p>Website:   www.concentrationgrid.com</p><p>Community:  www.concentrationgrid.net</p>
                        <p>Blog:  www.cgridid.com </p><p>Facebook:  www.facebook.com/ConcentrationGrid</p> 
                        <p>X:  www.twitter.com/ConcentrationGr</p>
                        <p>Instagram:  www.instagram.com/ConcentrationGrid</p> 
                        <p>Free web gameplay:  www.tryconcentrationgrid.com</p>
                        <p>Try the Concentration Grid app : available for Apple/iOS and Android devices<p>
                        <p>Post your best gridtimes on social media and to the Concentration Grid community</p>
                    </div>`
            attachment.push({
                name : data.snap_name+'.png',
                data : data.snapshow
            })
        }
        else{
            body = `<p>Concentration Grid</p>
                    <p>#ConcentrationGrid</p>
                    <p>"${ids[0]}"</p>
                    <p>"${ids[1]}"</p>
                    <p>@ConcentrationGr @Grid_IDx</p>
                    <div style = 'margin-top:30px'>
                        <p>Requested information from Concentration Grid</p>
                        <p>Website:   www.concentrationgrid.com</p>
                        <p>Community:  www.concentrationgrid.net</p>
                        <p>Blog:  www.cgridid.com </p>
                        <p>Facebook:  www.facebook.com/ConcentrationGrid</p> 
                        <p>X:  www.twitter.com/ConcentrationGr</p>
                        <p>Instagram:  www.instagram.com/ConcentrationGrid</p> 
                        <p>Free web gameplay:  www.tryconcentrationgrid.com</p>
                        <p>Try the Concentration Grid app : available for Apple/iOS and Android devices<p>
                        <p>Post your best gridtimes on social media and to the Concentration Grid community</p>
                    </div>"`
        }
        Email.send({
            SecureToken : "ac471b68-ab06-49f6-a889-fa5072a000ac",
            To : email,
            From : "email.concentrationgrid@gmail.com",
            Subject : "Concentration Grid",
            Body : body,
            Attachments : attachment
        }).then(
          message =>{
            let params = {
                title:'Mail Sent',
                msg:'Mail sent successfully. If not recevied in inbox please check your spam',
                showConfirmation:false,
                cancelText:"Continue"
            }
            this.alert (params)
          }
        );
    }
}